import React from "react";
import styles from "../styles/processingModal.module.scss";
import Form from "./form";

const ProcessingModal = ({
  setIsProcessingModalOpen,
  token,
  plusOnesSoldOut
}) => {

  return (
    <div className={styles.wholePageDark}>
      <div className={styles.container}>
        <div
          onClick={() => setIsProcessingModalOpen(false)}
          className={styles.closeButtonContainer}
        >
            <img className={styles.closeButton} src="/X.svg" alt="close icon" />
        </div>
        <Form token={token} plusOnesSoldOut={plusOnesSoldOut} />
      </div>
    </div>
  );
};

export default ProcessingModal;
