import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

import { injected, walletconnect, walletlink } from "../connectors";
import styles from "../styles/connectModal.module.scss";

import { resetWalletConnector } from "../utils/utils";

const ConnectModal = ({ setIsConnectModalOpen, signaturePending }) => {
  const { connector, activate, error } = useWeb3React();

  useEffect(() => {
    if (connector instanceof WalletConnectConnector && error) {
      resetWalletConnector(walletconnect);
    }
  }, [connector, error]);

  return (
    <div className={styles.wholePageDark}>
      <div className={styles.container}>
        <div
          onClick={() => setIsConnectModalOpen(false)}
          className={styles.closeButtonContainer}
        >
          <img className={styles.closeButton} src="/X.svg" alt="close icon" />
        </div>

        <div className={styles.sectionTop}>
          <h3>Connect Wallet</h3>
          <p>Your FLUF HAUS Ticket is Waiting!</p>

          {signaturePending && (
            <p className={styles.awaitingText}>
              Awaiting signature from wallet
            </p>
          )}
        </div>

        <div className={styles.sectionBottom}>
          <button
            disabled={signaturePending}
            onClick={() => {
              activate(injected);
            }}
            className={styles.connectButton}
          >
            MetaMask
          </button>
          <button
            disabled={signaturePending}
            onClick={() => {
              activate(walletconnect);
            }}
            className={styles.connectButton}
          >
            Wallet Connect
          </button>
          <button
            disabled={signaturePending}
            onClick={() => {
              activate(walletlink);
            }}
            className={styles.connectButton}
          >
            Coinbase Wallet
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConnectModal;
