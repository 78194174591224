import fetch from "node-fetch";
import { ethers } from "ethers";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

import abi from "../abis/abi.json";

const PRICE_PER_NFT = ethers.utils.bigNumberify("220000000000000000");
const CONTRACT_ADDRESS = "0xe51Aac67b09EaEd6d3D43e794D6bAe679Cbe09D8";
const SALT_API_URL = "https://burrows-salt-api.fluf.world";
const OWNERSHIP_API_URL = "https://burrows-ownership-api.fluf.world";
const TICKET_API_URL = "https://haus-ticketing-api.fluf.world";
// const TICKET_API_URL = "http://localhost:3001";


const getHeaders = () => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

const extractBody = async (res) => {
  if (!res.ok) {
    throw new Error(await res.text());
  } else {
    return res.json();
  }
};

let provider, signer, contractProvider, contractSigner;

if (window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();
  contractProvider = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);

  contractSigner = new ethers.Contract(CONTRACT_ADDRESS, abi, signer);
} else {
  // INFURA FALLBACK RPC HERE
  let fallbackProvider = ethers.getDefaultProvider("homestead", {});
  contractProvider = new ethers.Contract(
    CONTRACT_ADDRESS,
    abi,
    fallbackProvider
  );
}

export const updateProviderAndSigner = (library) => {
  if (library) {
    provider = new ethers.providers.Web3Provider(library.provider);
  } else {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  }
  signer = provider.getSigner();
  contractProvider = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);
  contractSigner = new ethers.Contract(CONTRACT_ADDRESS, abi, signer);
};

export const getTotalSupply = async () => {
  if (contractProvider) {
    let supply = await contractProvider.totalSupply();
    return supply.toLocaleString("en-US");
  } else {
    return "?";
  }
};

export const isDownloadPhase = async () => {
  return await fetch(`${TICKET_API_URL}/api/downloadphase`)
    .then((response) => extractBody(response))
    .catch((e) => {
      console.error(e);
    });
}

export const isGeneralSale = async () => {
  return await fetch(`${TICKET_API_URL}/api/salestatus`)
    .then((response) => extractBody(response))
    .catch((e) => {
      console.error(e);
    });
}

export const retrieveTokensForWhitelist = async (walletAddress) => {
  return await fetch(`${TICKET_API_URL}/api/validtokens`, {
    method: "POST",
    ...getHeaders(),
    body: JSON.stringify({ walletAddress })
  })
    .then((response) => extractBody(response))
    .catch((e) => {
      console.error(e);
    });
};

export const retrieveTokensForDownload = async (walletAddress, message, signature) => {
  return await fetch(`${TICKET_API_URL}/api/downloadabletokens`, {
    method: "POST",
    ...getHeaders(),
    body: JSON.stringify({ walletAddress, message, signature })
  })
    .then((response) => extractBody(response))
    .catch((e) => {
      console.error(e);
    });
}

export const retrieveTickets = async (ticketHashes) => {
  for (let hash of ticketHashes) {
    window.open(`${TICKET_API_URL}/api/retrieveticket/${hash}`, "_blank");
  }
}

export const signMessagePublic = async (
  walletToAuthenticate,
  provider,
  context
) => {
  let message = `This signature is to prove that you are the true owner of this wallet.`;

  let signature;

  provider = provider.toLowerCase()

  if (provider === "walletlink") {
    await sleep(400);
  }

  if (provider === "walletconnect" || provider === "walletlink") {
    signature = await context.library.provider.send("personal_sign", [
      ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message)),
      walletToAuthenticate.toLowerCase(),
    ]);
    console.log(`${walletToAuthenticate}`)
  } else {
    signature = await signer.signMessage(message);
  }

  return { message, signature };
};

export const submitTicketForm = async (
  fullName,
  email,
  plusOne,
  walletAddress,
  tokenId,
  contractAddress,
  message,
  signature
) => {
  return await fetch(`${TICKET_API_URL}/api/submitform`, {
    method: "POST",
    ...getHeaders(),
    body: JSON.stringify({
      fullName,
      email,
      plusOne,
      walletAddress,
      tokenId,
      contractAddress,
      message,
      signature,
    }),
  }).then((response) => extractBody(response));
};

export const checkWhitelisted = async (walletAddress) => {
  // return {whitelisted: true}
  return await fetch(`${TICKET_API_URL}/api/iswhitelisted`, {
    method: "POST",
    ...getHeaders(),
    body: JSON.stringify({
      walletAddress
    })
  }).then((response) => extractBody(response));
};

export const checkPlusOnes = async () => {
  return await fetch(`${TICKET_API_URL}/api/plusonesremaining`, {
    method: "GET"
  }).then((response) => extractBody(response))
}

export const retrievePublicSalt = async (message, signature) => {
  return await fetch(`${SALT_API_URL}/api/salt`, {
    method: "POST",
    ...getHeaders(),
    body: JSON.stringify({ message, signature }),
  }).then((response) => extractBody(response));
};

export const doPresaleMint = async (contractAddresses, tokenIds) => {
  if (contractAddresses.length !== tokenIds.length) {
    throw new Error("You must supply equal length arrays");
  }

  let tx = await contractSigner.mint(contractAddresses, tokenIds, {
    value: PRICE_PER_NFT.mul(contractAddresses.length),
  });

  return tx;
};

export const doPublicMint = async (amount, salt) => {
  let amountBN = new ethers.utils.BigNumber(amount);
  let totalAmount = amountBN.mul(PRICE_PER_NFT);
  let tx = await contractSigner.publicMint(salt.salt, salt.token, amount, {
    value: totalAmount,
  });

  return tx;
};

const sleep = async (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

export const retrieveWhetherTokensHaveMinted = async (
  contractAddresses,
  tokenIds
) => {
  return await contractProvider.hasTokenMintedBatch(
    contractAddresses,
    tokenIds
  );
};

export const saleStatus = async () => {
  return await fetch(`${SALT_API_URL}/salestarted`, {
    method: "GET",
  }).then((response) => extractBody(response));
};

export const resetWalletConnector = (connector) => {
  if (
    connector &&
    connector instanceof WalletConnectConnector &&
    (connector.walletConnectProvider?.wc?.uri ||
      connector.walletConnectProvider?.connector?.uri)
  ) {
    connector.walletConnectProvider = undefined;
  }
};
