import React from "react";

import styles from "../styles/footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.container}>
      <div>
        <img className={styles.logo} src="/flufLogoWhite.svg" alt="FLUF Logo" />
      </div>

      <div className={styles.copyBox}>
        <p>{"© Copyright Non Fungible Labs 2022,"}</p>
        <p>{"All Rights Reserved"}</p>
      </div>
    </div>
  );
};

export default Footer;
