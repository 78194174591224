import React, { useState, useEffect } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import * as API from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { injected, walletconnect, walletlink } from "../connectors";
import ConnectModal from "../components/ConnectModal";
import { useGlobalContext } from "../context/GlobalContext";

import styles from "../styles/connectWallet.module.scss";

const ConnectorNames = {
  Injected: "Metamask",
  Network: "Network",
  WalletConnect: "WalletConnect",
  WalletLink: "WalletLink",
  Ledger: "Ledger",
  Trezor: "Trezor",
  Lattice: "Lattice",
  Frame: "Frame",
  Authereum: "Authereum",
  Fortmatic: "Fortmatic",
  Magic: "Magic",
  Portis: "Portis",
  Torus: "Torus",
};

const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.WalletLink]: walletlink,
};

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network. Connect to ETH Mainnet.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect ||
    error instanceof UserRejectedRequestErrorFrame ||
    error.message === "User denied account authorization"
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
}

export default function ConnectWallet() {
  const context = useWeb3React();
  const { connector, library, account, deactivate, active, error } = context;

  const {
    setIsOnWhitelist,
    saleStatusHooks,
    setSaleStatusHooks,
  } = useGlobalContext();

  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [signaturePending, setSignaturePending] = useState(false);
  const [mintError, setMintError] = useState("");

  // const hoursMinSecs = { hours: 1, minutes: 20, seconds: 40 };

  useEffect(() => {
    if (error) {
      setIsConnectModalOpen(false);
    }
  }, [error]);

  const getSaleStatus = () => {
    try {
      API.saleStatus().then((resp) => {
        setSaleStatusHooks(resp);
      });
    } catch (e) {}
  };

  useEffect(() => {
    getSaleStatus();
  }, []);

  const navigate = useNavigate();

  const getActiveConnector = () => {
    let active;
    Object.keys(connectorsByName).map((name) => {
      const currentConnector = connectorsByName[name];
      const connected = currentConnector === connector;
      if (connected) {
        active = name;
      }
    });
    return active;
  };

  useEffect(() => {
    if (active && account) {
      API.updateProviderAndSigner(library);
      retrieveSalt();
    }
  }, [context, saleStatusHooks]);

  const retrieveSalt = async () => {
    try {
      setIsOnWhitelist(true);
      return navigate("mint");
    } catch (e) {
      setMintError(e.message);
      setIsConnectModalOpen(false);
      deactivate();
      console.error(e.message);
    } finally {
      setIsConnectModalOpen(false);
    }
  };

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState();

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const getSaleMessage = () => {
    if (
      saleStatusHooks.window1 == false &&
      saleStatusHooks.window2 == false &&
      saleStatusHooks.window3 == false &&
      saleStatusHooks.publicsale == false
    ) {
      return "The sale has not started yet, check back later.";
    }
  };

  return (
    <>
      {isConnectModalOpen && (
        <ConnectModal
          key={isConnectModalOpen}
          setIsConnectModalOpen={setIsConnectModalOpen}
          setActivatingConnector={setActivatingConnector}
          setMintError={setMintError}
          signaturePending={signaturePending}
        />
      )}
      <div className={styles.container}>
        <div className={`${styles.mintText}`}>
          <div className={styles.imgBox}>
            <img
              // className={styles.bossBear}
              src="/fh-logo-cropped.png"
              alt="FLUF HAUS logo"
            />
          </div>
          <div className={`${styles.textBox}`}>
            <p className={styles.textBold}>
              {!account ? "Your Wallet Is Not Connected" : getSaleMessage()}
            </p>
            <p
              className={`${styles.subText} text-xl mb-5 aos-init aos-animate`}
              data-aos="zoom-y-out"
              data-aos-delay="150"
            >
              {
                "To get your FLUF HAUS ticket you'll need to connect your Ethereum wallet."
              }
            </p>
            {!!error && (
              <div style={{ width: "100%" }}>
                <h4
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  className={`${styles.errorMessage} text-red-700`}
                >
                  {getErrorMessage(error)}
                </h4>
              </div>
            )}
            <div
              className="mx-auto sm:max-w-none sm:flex sm:justify-center aos-init aos-animate"
              data-aos="zoom-y-out"
              data-aos-delay="300"
            >
              {!account ? (
                <div className={styles.buttonContainer}>
                  <button
                    className={styles.connectButton}
                    size="large"
                    onClick={() => {
                      setIsConnectModalOpen(true);
                    }}
                  >
                    Connect Wallet
                  </button>
                </div>
              ) : (
                <div>
                  <div style={{ marginTop: 25 }}>
                    <button
                      className={styles.connectButton2}
                      onClick={() => {
                        setMintError("");
                        deactivate();
                      }}
                    >
                      Disconnect
                    </button>
                  </div>
                </div>
              )}
            </div>
            {mintError && (
              <div className={`${styles.errorMessage} mt-4 mb-3 text-red-700`}>
                {mintError}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
