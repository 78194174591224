import React, { useState, useEffect } from "react";
import styles from "../styles/form.module.scss";
import * as API from "../utils/utils";
import { useWeb3React } from "@web3-react/core";
import { injected, walletconnect, walletlink } from "../connectors";
import { Spinner } from "./Spinner.jsx";

const ConnectorNames = {
  Injected: "Metamask",
  Network: "Network",
  WalletConnect: "WalletConnect",
  WalletLink: "WalletLink",
  Ledger: "Ledger",
  Trezor: "Trezor",
  Lattice: "Lattice",
  Frame: "Frame",
  Authereum: "Authereum",
  Fortmatic: "Fortmatic",
  Magic: "Magic",
  Portis: "Portis",
  Torus: "Torus",
};

const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.WalletLink]: walletlink,
};

const Form = ({ token, plusOnesSoldOut }) => {
  const context = useWeb3React();
  const { connector } = context;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [plusOne, setPlusOne] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [failure, setFailure] = useState(false);
  const [showPlusOne, setShowPlusOne] = useState(false);
  const [isProcessingSubmit, setIsProcessingSubmit] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const checkPlusOnes = async () => {
      let res = await API.checkPlusOnes();
      setShowPlusOne(!res.soldout);
      if(res.soldout) setPlusOne(false);
    }
    checkPlusOnes();
  }, []);

  const getActiveConnector = () => {
    let active;
    Object.keys(connectorsByName).forEach((name) => {
      const currentConnector = connectorsByName[name];
      const connected = currentConnector === connector;
      if (connected) {
        active = name;
      }
    });
    return active;
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setError("");
      setIsProcessingSubmit(true);
      let currentConnector = getActiveConnector();
      await API.signMessagePublic(
        context.account,
        currentConnector,
        context
      ).then(async ({ message, signature }) => {
        if (message && signature) {
          await API.submitTicketForm(
            name,
            email,
            plusOne,
            context.account,
            token.tokenId,
            token.contractAddress,
            message,
            signature
          ).then((resp) => {
            if (resp.success === true) {
              setFailure(false);
            } else {
              setIsProcessingSubmit(false);
              setFailure(true);
            }
          });
        }
      });
    } catch (e) {
      console.error(e);
      setError(e.message);
      setIsProcessingSubmit(false);
      setFailure(true);
    } finally {
      setIsProcessingSubmit(false);
      setSubmitted(true);
      // stop loading?
    }
  };

  return (
    <div>
      {!submitted ? (
        <div>
          {!isProcessingSubmit ? (
            <div className={styles.container}>
              <form onSubmit={handleSubmit}>
                <h2>FILL OUT FORM</h2>
                <label>
                  <p className={styles.label}>Full Name</p>
                  <input
                    type="text"
                    placeholder="Please fill out this field"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
                <label>
                  <p className={styles.label}>Email</p>
                  <input
                    type="email"
                    placeholder="Please fill out this field"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                {!plusOnesSoldOut ? (
                  <div>
                    <h2>BRING A BUDDY</h2>
                    <div className={styles.optIns}>
                      <label className={styles.plusOne}>
                        <p className={styles.label}>Plus One</p>
                        <input
                          type="text"
                          placeholder="Add a plus one:"
                          disabled
                        />
                      </label>
                      <label className={styles.switch}>
                        <input
                          type="checkbox"
                          onChange={(e) => setPlusOne(e.target.checked)}
                        />
                        <span className={styles.sliderRound}></span>
                      </label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <input type="submit" value="GET TICKET" />
              </form>
            </div>
          ) : (
            <div className={styles.container}>``
              <div>
                <h2 className={styles.successHeader}>PROCESSING...</h2>
                <Spinner color="#ebebeb" height={32} width={32} />
                <div className={styles.successBody}>
                  <p>Interact with your wallet and sit tight!</p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : !failure ? (
        <div className={styles.container}>
          <h2 className={styles.successHeader}>SUCCESS!</h2>
          <p className={styles.successBody}>
            Congratulations {`${name}`}, you're going to FLUF HAUS LA.
          </p>
          <p className={styles.successBody}>
            We have sent a ticket confirmation to {`${email}`}
          </p>
          <p className={`${styles.successBody} ${styles.successBodyLast}`}>
            For upgrade questions, such as the Touchdown Zone VIP Experience or Bottle Service, please go to <a href="https://www.flufhaus.com/events/flufhaus-la#Tickets" target={"_blank"} >https://www.flufhaus.com/events/flufhaus-la#Tickets</a>.
          </p>
        </div>
      ) : (
        <div className={styles.container}>
          <h2 className={styles.successHeader}>TRY AGAIN!</h2>
          <p className={`${styles.successBody} ${styles.successBodyLast}`}>
            Error: {error ? error : ""} - Please try again.
          </p>
        </div>
      )}
    </div>
  );
};

export default Form;
