import React, { useState, useEffect } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { useNavigate } from "react-router-dom";

import ProcessingModal from "../components/ProcessingModal";

import { injected, walletconnect, walletlink } from "../connectors";
import { useGlobalContext } from "../context/GlobalContext";
import * as CompressedImages from "../utils/compressed_images.js";
import * as API from "../utils/utils";
import _ from "lodash";
import styles from "../styles/mint.module.scss";

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network. Connect to ETH Mainnet.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
}

const getClass = (numSelected) => {
  if (numSelected === 1) return "one";
  return "zero";
};

const getColorClass = (numSelected) => {
  if (numSelected >= 1) return styles.one;
  return styles.zero;
};
const getBorderColor = (numSelected) => {
  if (numSelected >= 1) return "#B9BBF1";
  return "#5b5b5b";
};

const ConnectorNames = {
  Injected: "Metamask",
  Network: "Network",
  WalletConnect: "WalletConnect",
  WalletLink: "WalletLink",
  Ledger: "Ledger",
  Trezor: "Trezor",
  Lattice: "Lattice",
  Frame: "Frame",
  Authereum: "Authereum",
  Fortmatic: "Fortmatic",
  Magic: "Magic",
  Portis: "Portis",
  Torus: "Torus",
};

const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.WalletLink]: walletlink,
};

// init DOWNLOAD_STATE & GENERAL_SALE:
// todo: review init below - need to use React States and Events?
let DOWNLOAD_STATE = true;
let GENERAL_SALE = false;
try {
  // API.isDownloadPhase().then(resp => {
  //   DOWNLOAD_STATE = resp.downloadphase;
  // });
  // API.isGeneralSale().then(resp => {
  //   GENERAL_SALE = resp.generalsale;
  // });
} catch (e) {
  console.error(e);
}

function Mint() {
  const context = useWeb3React();
  const { account, active, error, connector } = context;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mintError, setMintError] = useState("");
  const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false);
  const [tokensInWallet, setTokensInWallet] = useState([]);
  const [tokensLoading, setTokensLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState(0);
  const [didHaveTokens, setDidHaveTokens] = useState(false);
  const [plusOnesSoldOut, setPlusOnesSoldOut] = useState(false);

  const {
    setTransactionProcessing,
    isOnWhitelist,
    setIsOnWhitelist,
    saleStatusHooks,
    setTx,
  } = useGlobalContext();

  useEffect(() => {
    if (error) {
      setIsModalOpen(false);
    }
  }, [error]);

  const getActiveConnector = () => {
    let active;
    Object.keys(connectorsByName).forEach((name) => {
      const currentConnector = connectorsByName[name];
      const connected = currentConnector === connector;
      if (connected) {
        active = name;
      }
    });
    return active;
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!active) {
      console.log(`WALLET NOT ACTIVATED ON MINT PAGE`);
      navigate("/");
    }
  }, [active, context, navigate]);

  useEffect(() => {
    // todo - swap walletAddress with context.account
    // let walletAddress = "0x00F791f9fed6fE53A3C6794d4dD12a966ADe331e"
    let walletAddress = context.account;
    const checkWhitelist = async () => {
      if (context.account) {
        let res = await API.checkWhitelisted(walletAddress);
        setIsOnWhitelist(res.whitelisted);
      } else {
        setIsOnWhitelist(false);
      }
    };
    checkWhitelist();
    if (isOnWhitelist) {
      DOWNLOAD_STATE ? getTokensForDownload() : getTokensForWallet();
    }
  }, []);

  const getTokensForWallet = async () => {
    try {
      setTokensLoading(true);
      let allowedContractsInWindow = { 2: {} };
      // Window 2;
      allowedContractsInWindow[2][
        "0xccc441ac31f02cd96c153db6fd5fe0a2f4e6a68d"
      ] = true; // FLUF
      allowedContractsInWindow[2][
        "0x35471f47c3c0bc5fc75025b97a19ecdde00f78f8"
      ] = true; // Partybears

      // todo - swap walletAddress with context.account
      // let walletAddress = "0x00F791f9fed6fE53A3C6794d4dD12a966ADe331e"
      let walletAddress = context.account;
      await API.retrieveTokensForWhitelist(walletAddress).then(
        async (response) => {
          let resp = [];
          let keys = Object.keys(response);
          for (let key of keys) {
            let tokens = response[key];
            for (let token of tokens) {
              resp.push({
                contractAddress: key,
                tokenId: parseInt(token),
              });
            }
          }
          setTokensInWallet(resp);
        }
      );
    } catch (e) {
      console.error(e);
    } finally {
      setTokensLoading(false);
      setSelectedItems(0);
    }
  };

  const getTokensForDownload = async () => {
    try {
      let walletAddress = context.account;
      let currentConnector = getActiveConnector();
      await API.signMessagePublic(
        context.account,
        currentConnector,
        context
      ).then(async ({ message, signature }) => {
        if (message && signature) {
          await API.retrieveTokensForDownload(
            walletAddress,
            message,
            signature
          ).then(async (response) => {
            let resp = [];
            for (let entry of response) {
              resp.push({
                contractAddress: entry.contractAddress,
                tokenId: parseInt(entry.tokenId),
                ticketHash: entry._id,
              });
            }
            setTokensInWallet(resp);
          });
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      setTokensLoading(false);
      setSelectedItems(0);
    }
  };

  const doMint = async () => {
    await checkPlusOnes();
    setIsProcessingModalOpen(true);
  };

  const downloadTickets = async () => {
    // set loading screen or spinner on button to await download
    let selectedTokens = tokensInWallet.filter((e) => e.selected);
    let tickethashes = selectedTokens.map((e) => e.ticketHash);
    await API.retrieveTickets(tickethashes);
  };

  const addToSelection = (item) => {
    let tokens = _.cloneDeep(tokensInWallet);
    let alreadySelected = tokensInWallet.filter((e) => e.selected);
    let willSelect = false;

    let selectionCount = 0;
    for (let i = 0; i < tokens.length; i++) {
      if (
        tokens[i].contractAddress == item.contractAddress &&
        tokens[i].tokenId == item.tokenId
      ) {
        if (tokens[i].selected == true) {
          willSelect = false;
        } else {
          willSelect = true;
        }
        tokens[i].selected = willSelect;
      } else if (tokens[i].selected) {
        tokens[i].selected = false;
      }
      if (tokens[i].selected == true) {
        selectionCount++;
      }
    }
    setSelectedItems(selectionCount);
    setTokensInWallet(tokens);
  };

  // const resetSelection = async () => {
  //   let tokens = _.cloneDeep(tokensInWallet);
  //   let alreadySelected = tokensInWallet.filter((e) => e.selected);
  //   if (alreadySelected.length > 0) {
  //     for (let token of alreadySelected) {
  //       token.selected = false;
  //     }
  //     setSelectedItems(0);
  //   }
  // }

  useEffect(() => {
    if (!isProcessingModalOpen) {
      setMintError("");
      if (!DOWNLOAD_STATE) getTokensForWallet();
      setSelectedItems(0);
    }
  }, [isProcessingModalOpen]);

  async function checkPlusOnes() {
    let res = await API.checkPlusOnes();
    let soldout = res.soldout;
    if (!plusOnesSoldOut && soldout) setPlusOnesSoldOut(true);
  }

  return (
    <>
      {isProcessingModalOpen && (
        <ProcessingModal
          setIsProcessingModalOpen={setIsProcessingModalOpen}
          token={tokensInWallet.filter((e) => e.selected)[0]}
          plusOnesSoldOut={plusOnesSoldOut}
        />
      )}
      <div className={`${styles.container} text-center`}>
        <div className={`${styles.innerContainer} max-w-3xl mx-auto`}>
          {!!error && (
            <div style={{ width: "100%" }}>
              <h4
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
                className="text-red-700"
              >
                {getErrorMessage(error)}
              </h4>
            </div>
          )}
          <div
            className="sm:flex sm:justify-center aos-init aos-animate"
            data-aos="zoom-y-out"
            data-aos-delay="300"
            style={{ height: "100%" }}
          >
            {account && (
              <div className={styles.textContainer}>
                {isOnWhitelist ? (
                  <>
                    <div className={styles.scrollBox}>
                      {tokensInWallet.length === 0 && (
                        <div className={styles.scrollBoxInfo}>
                          {!tokensLoading ? (
                            didHaveTokens ? (
                              `You don't own any tokens to get tickets with.`
                            ) : (
                              <div>
                                <p>
                                  It seems that you don't have any tokens that
                                  can get tickets.
                                </p>
                                <br />
                                <p>
                                  Already have tickets? Head on over to{" "}
                                  <a
                                    href="https://www.flufhaus.com/events/flufhaus-la#Tickets"
                                    target={"_blank"}
                                    style={{ textDecoration: "underline" }}
                                  >
                                    FLUF Haus LA
                                  </a>{" "}
                                  to check out upgrade options.
                                </p>
                              </div>
                            )
                          ) : (
                            "Loading..."
                          )}
                        </div>
                      )}
                      {tokensInWallet.length > 0 && (
                        <div className={styles.gridBox}>
                          {tokensInWallet.map((item) => (
                            <div
                              key={`${item.contractAddress}_${item.tokenId}`}
                            >
                              <div
                                className={`${styles.gridCard} ${
                                  item.selected && styles.hover
                                }`}
                                onClick={() => {
                                  addToSelection(item);
                                }}
                              >
                                <img
                                  src={CompressedImages.getCompressedImage(
                                    item.contractAddress,
                                    item.tokenId
                                  )}
                                  alt="nft"
                                />
                                <p>ID: {item.tokenId}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className={styles.scrollBox}>
                    <div className={styles.scrollBoxInfo}>
                      Sorry, you are not on the whitelist for this event.
                    </div>
                  </div>
                )}
                {isOnWhitelist ? (
                  <div className={styles.mintButtonContainer}>
                    {DOWNLOAD_STATE ? (
                      <p className={styles.mintSectionText}>
                        SELECT 1 CHARACTER AT A TIME TO DOWNLOAD ITS FLUF HAUS
                        TICKET
                      </p>
                    ) : GENERAL_SALE ? (
                      <p className={styles.mintSectionText}>
                        SELECT 1 CHARACTER FOR A FLUF HAUS TICKET
                      </p>
                    ) : (
                      <p className={styles.mintSectionText}>
                        SELECT 1 CHARACTER AT A TIME FOR A FLUF HAUS TICKET
                      </p>
                    )}

                    <div className={`${styles.buttonBox}`}>
                      <div
                        className={`${styles.innerBox} ${getColorClass(
                          selectedItems
                        )}`}
                      >
                        <div>
                          <p className={styles.bigNum}>{selectedItems}</p>
                        </div>
                      </div>
                      <button
                        className={`${styles.button}`}
                        style={{
                          borderColor: getBorderColor(selectedItems),
                        }}
                        size="large"
                        disabled={selectedItems <= 0}
                        onClick={async () => {
                          DOWNLOAD_STATE
                            ? await downloadTickets()
                            : await doMint();
                        }}
                      >
                        {DOWNLOAD_STATE ? (
                          <p className={styles.buttonText}>DOWNLOAD TICKET</p>
                        ) : (
                          <p className={styles.buttonText}>GET TICKET</p>
                        )}
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Mint;
