import { useLayoutEffect, useState } from "react";
import { BrowserView } from "react-device-detect";

import { Windmill } from "@windmill/react-ui";
import Mint from "./pages/Mint";
import ConnectWallet from "./pages/ConnectWallet";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import styles from "./styles/home.module.scss";

function App() {

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useLayoutEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* <BrowserView>
        {(dimensions.height < 480 || dimensions.width < 480) && (
          <div className={styles.resize}>
            <div className={styles.resizeContainer}>
              <img src="/resize.svg" width={72} height={54} alt="resize icon" />
              <h5>Your browser is too small</h5>
              <h6>
                Resize your browser to be at least 480px wide/high to get back
                into the app.
              </h6>
            </div>
          </div>
        )}
      </BrowserView> */}
      <div className={styles.container}>
        <Windmill>
          <BrowserRouter>
            <div className={styles.headerContainer}>
              <h1 className={`${styles.headerText} ${styles.glitch}`} data-text="FLUF HAUS">
                <a href="https://www.flufhaus.com/" rel="noopener">FLUF HAUS</a>
              </h1>
            </div>
            <div className={`${styles.main}`}>
              <Routes>
                <Route path="/" element={<ConnectWallet />} />
                <Route path="/mint" element={<Mint />} />
              </Routes>
            </div>
            <Footer />
          </BrowserRouter>
        </Windmill>
      </div>
    </>
  );
}

export default App;
